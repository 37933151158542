.buyer-product-view{
    width: 100%;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buyer-product-view-container{
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
}

.buyer-product-view-container h3{
    font-weight: 600;
    font-size: 27px;
    margin-left: 10px;
}

.buyer-product-view-container-product-image{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
    margin: 1rem 0px;
}

.buyer-product-view-container-product-image-main{
    width: 50%;
    justify-content: center;
    align-items: center;
    margin: 10px;
}

.buyer-product-view-container-product-image-main img{
    width: 100%;
    height: 410px;
    border-radius: 20px 0px 0px 20px;
}

.buyer-product-view-container-product-image-secondary-first{
    display: flex;
    flex-direction: column;
    width: 25%;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.buyer-product-view-container-product-image-secondary-first img{
    width: 100%;
    margin: 5px;
    height: 200px;
}

.buyer-product-view-container-product-image-secondary-second{
    display: flex;
    flex-direction: column;
    width: 25%;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.buyer-product-view-container-product-image-secondary-second .img-1{
    width: 100%;
    margin: 5px;
    height: 200px;
    border-radius: 0px 20px 0px 0px;
}

.buyer-product-view-container-product-image-secondary-second .img-2{
    width: 100%;
    margin: 5px;
    height: 200px;
    border-radius: 0px 0px 20px 0px;
}

.buyer-product-view-container-product-details{
    display: flex;
    justify-content: center;
    padding: 10px 20px;
}

.buyer-product-view-container-product-details-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
}

.buyer-product-view-container-product-details-info .description{
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: large;
    width: 100%;
}

.buyer-product-view-container-product-details-info .seller-account{
    display: flex;
    justify-content: start;
    align-items: center;
    border-top: 1px solid rgb(220, 220, 220);
    border-bottom: 1px solid rgb(220, 220, 220);
    padding: 20px 5px;
    margin: 20px 40px 20px 0px;
}

.buyer-product-view-container-product-details-info .seller-account .profile-picture{
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buyer-product-view-container-product-details-info .seller-account .profile-picture img{
    width: 75%;
    border-radius: 50%;
}

.buyer-product-view-container-product-details-info .seller-account .profile-desc{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin-left: 10px;
}

.buyer-product-view-container-product-details-info .seller-account .profile-desc p{
    margin-bottom: 0rem;
}

.buyer-product-view-container-product-details-info .seller-account .profile-desc h6{
    margin-bottom: 0rem;
    font-size: 17px;
}

.buyer-product-view-container-product-details-info .product-perks{
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    margin: 15px;
}

.buyer-product-view-container-product-details-info .product-perks .icon{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2rem;
}

.buyer-product-view-container-product-details-info .product-perks .perk-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}

.buyer-product-view-container-product-details-info .product-perks .perk-info p{
    margin-bottom: 0rem;
}

.buyer-product-view-container-product-details-info .product-perks .perk-info h6{
    margin-bottom: 0rem;
    font-size: 17px;
}

.buyer-product-view-container-product-details-info .what-will-you-do{
    margin: 3rem 5px;
}

.buyer-product-view-container-product-details-info .what-will-you-do p{
    margin-bottom: 1.8rem;
    font-size: 17px;
}

.buyer-product-view-container-product-details-info .what-will-you-do h6{
    margin-bottom: 0.8rem;
    font-size: 17px;
}

.buyer-product-view-container-product-details-info .meet-your-seller{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.buyer-product-view-container-product-details-info .meet-your-seller .card{
    width: 60%;
    min-height: 20svh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin: 1rem 0px;
}

.buyer-product-view-container-product-details-info .meet-your-seller .card img{
    width: 20%;
    border-radius: 50%;
}

.buyer-product-view-container-product-details-info .meet-your-seller .card h5{
    margin-top: 10px;
}

.buyer-product-view-container-product-details-info .meet-your-seller .card p{
    margin-top: 5px;
}

.buyer-product-view-container-product-details-info .meet-your-seller .seller-about{
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 5px 0px;
}

.buyer-product-view-container-product-details-info .meet-your-seller .seller-about .icon{
    margin-right: 15px;
}

.buyer-product-view-container-product-details-info .meet-your-seller .seller-about p{
    margin: 0rem;
}

.buyer-product-view-container-product-details-info .meet-your-seller p{
    margin: 20px 0px;
}

.buyer-product-view-container-product-details-request{
    width: 40%;
    display: flex;
    justify-content: end;
    align-items: start;
}

.buyer-product-view-container-product-details-request .price-card{
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    height: 35vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
}

.buyer-product-view-container-product-details-request .price-card .btn {
    border-radius: 10px;
    width: 80%;
    color: white;
    background-color: #19A741;
}

.quantity-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0rem;
}

.quantity-input-btn-plus {
    background-color: #19A741;
    color: white;
    font-weight: 700;
    padding: 2px;
    border-radius: 5px 0px 0px 5px;
    cursor: pointer;
}

.quantity-input-btn-plus.disabled {
    background-color: #63cd81;
}

.quantity-input-btn-minus {
    background-color: #19A741;
    color: white;
    font-weight: 700;
    padding: 2px;
    border-radius: 0px 5px 5px 0px;
    cursor: pointer;
}

.quantity-input-btn-minus.disabled {
    background-color: #63cd81;
}

.quantity-input-counter {
    border: 1px solid gray;
    padding: 2px 15px;
}