.about-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50svh;
}

.about-content h2 {
    margin: 2rem 0;
    font-size: 2.8rem;
    font-weight: bold;
}

.about-content p {
    width: 80%;
    text-align: justify;
    font-size: 18px;
    line-height: 2.3rem;
}